const ExternalIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon FerLab / Outlined / External">
      <path
        id="Vector"
        d="M21.7084 20.625V3.375C21.7084 2.96016 21.3732 2.625 20.9584 2.625H3.70837C3.29353 2.625 2.95837 2.96016 2.95837 3.375V11.8125C2.95837 11.9156 3.04275 12 3.14587 12H4.45837C4.5615 12 4.64587 11.9156 4.64587 11.8125V4.3125H20.0209V19.6875H12.5209C12.4177 19.6875 12.3334 19.7719 12.3334 19.875V21.1875C12.3334 21.2906 12.4177 21.375 12.5209 21.375H20.9584C21.3732 21.375 21.7084 21.0398 21.7084 20.625ZM10.2849 15.3117L11.5084 16.5352C11.5333 16.5599 11.5646 16.5772 11.5989 16.5851C11.6331 16.5929 11.6689 16.591 11.7021 16.5795C11.7353 16.568 11.7646 16.5475 11.7867 16.5202C11.8088 16.4928 11.8228 16.4599 11.8271 16.425L12.3193 12.2203C12.3334 12.1008 12.2326 11.9977 12.1107 12.0117L7.90603 12.5039C7.75134 12.5227 7.68572 12.7125 7.79587 12.8227L9.024 14.0508L3.01931 20.0555C2.94665 20.1281 2.94665 20.2477 3.01931 20.3203L4.01306 21.3141C4.08572 21.3867 4.20525 21.3867 4.2779 21.3141L10.2849 15.3117Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default ExternalIcon;
